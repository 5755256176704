import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Stage from 'ui-kit/stage';
import Intro from 'ui-kit/intro';
import Section from 'ui-kit/section';
import Textlink from 'ui-kit/textlink';
import Container from 'ui-kit/container';
import { Headline } from 'ui-kit/typography';

import Layout from '../../components/layout';

const ContactPage = ({ data }) => {
    const stageImage = data.stageImage.childImageSharp;
    const productionFacilities = data.productionFacilities.edges;

    return (
        <Layout description="Kontakt">
            <Stage
                image={stageImage}
                imageAlt="Kinderhand hält einen Strauß Wildblumen"
                tag="Service & Kontakt | Kontakt"
                headline={{ text: 'Wir helfen Ihnen gerne weiter', level: 'h1' }}
                bottomShade
            />
            <Section container="l">
                <Intro title="Ihr Kontakt zu den Qualitäts- und Genussexperten">
                    Sie haben Wünsche, Fragen oder Anregungen – wir sind persönlich für Sie da und
                    unterstützen Sie gerne mit Rat und Tat.
                </Intro>
            </Section>
            <Section container="l" bg="gray" textAlign="center">
                <Headline level="h3">Digitale Anfragen</Headline>
                Bitte richten Sie Ihre Anfragen an: <br />
                <Textlink to="mailto:info@suedbayerische-fleischwaren.de" gap="xl">
                    info@suedbayerische-fleischwaren.de
                </Textlink>
                <Headline level="h3" gap="xl">
                    Adressdaten unserer Standorte
                </Headline>
                {productionFacilities.map(({ node: { address } }, index) => (
                    <>
                        <Container gap={index !== productionFacilities.length - 1 ? `xxl` : 'none'}>
                            <address>
                                <Headline level="h4" gap="s">
                                    Südbayerische Fleischwaren GmbH
                                </Headline>
                                {address.street}
                                <br />
                                {address.zipCity}
                                <br />
                                Tel.:{' '}
                                <Textlink href={`tel:${address.phone}`}>{address.phone}</Textlink>
                                <br />
                            </address>
                        </Container>
                    </>
                ))}
            </Section>
        </Layout>
    );
};

ContactPage.propTypes = {
    data: PropTypes.shape({
        stageImage: PropTypes.object,
        productionFacilities: PropTypes.object,
    }).isRequired,
};

export const query = graphql`
    query {
        stageImage: file(
            relativePath: { eq: "service-and-contact-pages/buehne-service-und-kontakt.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        allFaqJson {
            edges {
                node {
                    title
                    questions {
                        title
                        answer
                        detailLink
                    }
                }
            }
        }
        productionFacilities: allProductionFacilitiesJson {
            edges {
                node {
                    address {
                        street
                        zipCity
                        phone
                    }
                }
            }
        }
    }
`;

export default ContactPage;
